import React from 'react';
import {useEffect} from 'react';
import {useCallback} from 'react';
import {useState} from 'react';
import DateCompareComponent from '../../../components/dateCompareComponent';
import DateRangeComponent from '../../../components/dateRangeComponent';
import Page from '../../../components/page';
import SelectComponent from '../../../components/selectComponent';
import DefaultLineGraph from '../../../components/graphs/defaultLineGraph';
import DefaultBarGraph from '../../../components/graphs/defaultBarGraph';
// import DefaultTable from '../../../components/defaultTable';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import apiToCall from '../../../features/apiToCall';
import SelectRFMComponent from '../../../components/selectRfmComponent';

import Indicator from './indicator';
import {getRevenueValues} from './revenueValues';
import {saveAs} from 'file-saver';
import {RequestApi} from '../../../api/requestReports';
import {Spinner} from '@shopify/polaris';
import Box from '../../../lib/Box';
import Card from '../../../lib/Card';
import Inline from '../../../lib/Inline';
import Button from '../../../lib/Button';

import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  previousPeriod,
  previousYear,
  toClickHouseDate,
  convertToOrgTimezone,
  oneYearBefore,
  // toShortDate,
} from '../../../features/convertDates';
import config from '../../../config';
import {Loading} from '@shopify/polaris';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {BarChart, LineChart} from '@shopify/polaris-viz';
import DefaultIndexTable from '../../../components/defaultIndexTable';
import {useDateRangeStore, useStoreInfo} from '../../../app/store';
import DefaultSelect from '../../../components/defaultSelect';
import {getStore} from '../../../api/store';
import {track} from '../../../features/track';
import Alert from '../../../components/alert';
import {LocalStorageService} from '../../../service/localStorage';
import MultiSelect from '../../../components/multiSelect';
import useApiCall from '../../../hooks/useApiCall';
import DownloadModal from '../../../components/Modals/downloadModal';
import useSearchParamStoreSync from '../../../hooks/useSearchParamsStoreSync';
const npsOptions = [
  {label: 'All Products', value: 'All Products'},
  {label: 'Promoters', value: 'promoters'},
  {label: 'Neutrals', value: 'neutrals'},
  {label: 'Detractors', value: 'detractors'},
];

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};
const dateCompareObj = {
  previousPeriod: previousPeriod,
  previousYear: previousYear,
  noComparison: () => ['', ''],
};

const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};
const dateCompareValueToLable = {
  previousPeriod: 'Previous Period',
  previousYear: 'Previous Year',
  noComparison: 'No Comparison',
};
const ReportPage = () => {
  const param = useParams();
  let {
    selectedDateRange,
    setSelectedDateRange,
    selectedCompare,
    setSelectedCompare,
    durationFilter,
    setDurationFilter,
    selectedDateRangeDates,
    setSelectedDateRangeDates,
    setSelectedCompareDates,
    selectedCompareDates,
  } = useDateRangeStore(state => state);
  useSearchParamStoreSync(['durationFilter', 'selectedDateRangeDates']);

  // selectedDateRange = apiToCall[param.name]?.selectedDateRange || selectedDateRange;

  const isCohortReport = param.name.indexOf('cohort') !== -1 ? true : false;
  const [sortOrder, setSortOrder] = useState(apiToCall[param.name].sortOrder);
  const [queryValue, setQueryValue] = useState('');
  const [revenue, setRevenue] = useState([]);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [selectedSourceName, setSelectedSourceName] = useState('All Sources');
  const {sourceNames, setSourceNames} = useStoreInfo(state => state);

  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(apiToCall[param.name].sortBy);
  const [showAlert, setShowAlert] = useState('');

  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);

  const [download, setDownload] = useState({state: 'Success'});

  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const navigate = useNavigate();
  const [resultType, setResultType] = useState('relative');
  const [rfmSegment, setRfmSegment] = useState('All Customers');
  const [npsOn, setNpsOn] = useState(false);
  const [selectedNps, setSelectedNps] = useState('');

  useEffect(() => {
    if (selectedDateRange && !isCohortReport) {
      setSelectedDateRangeDates({
        start: dateRangeObj[selectedDateRange]()[0],
        end: dateRangeObj[selectedDateRange]()[1],
      });
    }
    RequestApi.updateLastViewed({slag: param.name});
    track('Page View', {
      name: 'Report',
    });
  }, []);

  useEffect(() => {
    const userDetails = LocalStorageService.getUserOrg();
    if (userDetails?.reportSettings?.nps?.product === true) {
      setNpsOn(true);
    }
  }, []);

  const activator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        Compare:{' '}
        {selectedCompare
          ? dateCompareValueToLable[selectedCompare]
          : dateToNormalString(selectedCompareDates)}
      </Button>
    </div>
  );

  const getDates = (dates, selectedCompar) => {
    setSelectedCompareDates(dates);
    setSelectedCompare(selectedCompar[0]);
  };
  const getDateRangeDates = (dates, selectedDateRange) => {
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };
  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );

  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );
  useEffect(() => {
    if (!sourceNames.length) {
      getStore().then(res => {
        setSourceNames(res.data.data.sourceName);
      });
    }

    // compare Dates auto change on selected date changed
    if (selectedCompare) {
      let [sdate, edate] = dateCompareObj[selectedCompare](selectedDateRangeDates);
      setSelectedCompareDates({start: sdate, end: edate});
    }
  }, [selectedDateRangeDates]);
  const fetchInfoData = async () => {
    if (apiToCall[param.name].graph === 'line' && !apiToCall[param.name].noInfoTab) {
      let params = {type: 'info', rfmSegment, sourceName: selectedSourceName, nps: selectedNps[0]};
      let res = await apiToCall[param.name].api({params});
      if (res.status) {
        let rvalues = getRevenueValues(res.data.data[0], param.name === 'returns');
        setRevenue([...rvalues]);
      }
      return res.data;
    }
  };

  const infoApiDependencies = [rfmSegment, selectedSourceName, selectedNps];

  const fetchGraphData = async () => {
    if (apiToCall[param.name].noGraph) {
      return;
    }
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let params = {};
    if (selectedCompareDates.start !== 'Invalid Date') {
      let compareStartDate = convertToOrgTimezone(
        toClickHouseDate(selectedCompareDates.start, 'start')
      );
      let compareEndDate = convertToOrgTimezone(toClickHouseDate(selectedCompareDates.end, 'end'));
      params = {...params, compareStartDate, compareEndDate};
    }
    params = {
      ...params,
      startDate,
      endDate,
      type: 'graph',
      durationFilter,
      rfmSegment,
      sourceName: selectedSourceName,
      nps: selectedNps[0],
    };
    let res = await apiToCall[param.name].api({params});
    return res.data;
  };

  const graphApiDependencies = [
    selectedDateRangeDates,
    durationFilter,
    selectedCompareDates,
    rfmSegment,
    selectedSourceName,
    selectedNps,
  ];

  const fetchTableData = async () => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let params = {
      startDate,
      endDate,
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'table',
      durationFilter,
      reportType: apiToCall[param.name].cohort,
      resultType,
      rfmSegment,
      sourceName: selectedSourceName,
      nps: selectedNps,
    };
    let res = await apiToCall[param.name].api({params});
    return res.data;
  };

  const tableApiDependencies = [
    selectedDateRangeDates,
    page,
    queryValue,
    sortBy,
    sortOrder,
    durationFilter,
    resultType,
    rfmSegment,
    selectedSourceName,
    selectedNps,
  ];

  const graphData = useApiCall(fetchGraphData, graphApiDependencies);
  const tableData = useApiCall(fetchTableData, tableApiDependencies, config.SEARCH_DELAY);
  const infoData = useApiCall(fetchInfoData, infoApiDependencies);

  const handleDownload = async email => {
    setShowAlert('');
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    setDownload({state: 'Loading'});
    const params = {
      type: 'download',
      startDate,
      endDate,
      search: queryValue,
      sortBy,
      sortOrder,
      page,
      durationFilter,
      reportType: apiToCall[param.name].cohort,
      resultType,
      rfmSegment,
      sourceName: selectedSourceName,
      nps: selectedNps,
    };
    params.email = email;
    try {
      let response = await apiToCall[param.name].api({params});
      setDownload({state: 'Success'});
      setShowAlert(`Report will be mailed to you shortly on ${email}`);
    } catch (e) {
      setDownload({state: 'Error'});
    }
  };
  return (
    <>
      <Page
        breadcrumbs={[
          {
            content: 'Back-button',
            onAction: () => {
              navigate(-1);
            },
          },
        ]}
        secondaryActions={[
          // {
          //   content: 'Create signal',
          //   destructive: false,
          //   onAction: () => {
          //     navigate(`/signal-new/${param.name}`);
          //   },
          // },
          {
            content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
            destructive: false,
            onAction: () => {
              setOpenDownloadModal(true);
            },
          },
        ]}
        title={apiToCall[param.name].title}
        subtitle={apiToCall[param.name].description || ''}
      >
        {param.name === 'customer-cohort' || param.name === 'revenue-cohort'
          ? tableData.state === 'Loading' && <Loading />
          : apiToCall[param.name].graph === 'line' &&
            param.name !== 'abandoned-checkouts' &&
            param.name !== 'nps-product'
          ? (infoData.state === 'Loading' ||
              tableData.state === 'Loading' ||
              tableData.state === 'Loading') && <Loading />
          : (tableData.state === 'Loading' || graphData.state === 'Loading') && <Loading />}
        {!isCohortReport && (
          <Box paddingBlockEnd={'4'}>
            {apiToCall[param.name].graph === 'line' && !apiToCall[param.name].noInfoTab && (
              <ErrorBoundary
                fallback={
                  <Card>
                    <Card.Section>
                      <div>An error has occured</div>
                    </Card.Section>
                  </Card>
                }
              >
                <Indicator
                  symbol={apiToCall[param.name].symbol}
                  data={infoData.data}
                  revenue={revenue}
                  state={infoData.state}
                />
              </ErrorBoundary>
            )}
          </Box>
        )}
        <Box paddingBlockEnd={'4'}>
          <Inline>
            {!isCohortReport && (
              <DateRangeComponent
                currentDates={selectedDateRangeDates}
                activator={dateRangeActivator}
                togglePopoverActive={dateRangeTogglePopoverActive}
                popoverActive={dateRangePopoverActive}
                getDates={getDateRangeDates}
                currentDateOption={selectedDateRange}
              />
            )}
            {apiToCall[param.name].graph === 'line' && !isCohortReport && (
              <DateCompareComponent
                currentDates={selectedCompareDates}
                activator={activator}
                togglePopoverActive={togglePopoverActive}
                popoverActive={popoverActive}
                selectedCompare={selectedCompare}
                getDates={getDates}
                selectedDateRangeDates={selectedDateRangeDates}
              />
            )}
            {apiToCall[param.name].graph === 'line' && !isCohortReport && (
              <SelectComponent
                setDurationFilter={setDurationFilter}
                durationFilter={durationFilter}
                setPage={setPage}
                setQueryValue={setQueryValue}
              />
            )}
            {apiToCall[param.name].rfmSegmentation === true && !isCohortReport && (
              <SelectRFMComponent
                setRfmSegment={setRfmSegment}
                rfmSegment={rfmSegment}
                setPage={setPage}
                setQueryValue={setQueryValue}
              />
            )}
            {apiToCall[param.name].sourceFilter && (
              <DefaultSelect
                value={selectedSourceName}
                setValue={setSelectedSourceName}
                list={sourceNames}
                setPage={setPage}
                setQueryValue={setQueryValue}
              />
            )}
            {npsOn === true && param.name !== 'nps-product' && param.name !== 'rfm-analysis' && (
              <MultiSelect
                selected={selectedNps}
                setSelected={setSelectedNps}
                options={npsOptions}
                title={'Product NPS'}
                disableMultiple
                setPage={setPage}
              />
            )}
          </Inline>
        </Box>
        {!isCohortReport && (
          <>
            <Box paddingBlockEnd={'4'}>
              {apiToCall[param.name].graph === 'line' ? (
                <Card>
                  <Card.Section>
                    <ErrorBoundary fallback={<LineChart data={[]} state="Error" theme="Light" />}>
                      <DefaultLineGraph
                        data={graphData.data || []}
                        compareStartDate={
                          selectedCompareDates.start !== 'Invalid Date'
                            ? toClickHouseDate(selectedCompareDates.start, 'start')
                            : null
                        }
                        compareEndDate={
                          selectedCompareDates.start !== 'Invalid Date'
                            ? toClickHouseDate(selectedCompareDates.end, 'end')
                            : null
                        }
                        startDate={toClickHouseDate(selectedDateRangeDates.start, 'start')}
                        endDate={toClickHouseDate(selectedDateRangeDates.end, 'end')}
                        param={param}
                        symbol={apiToCall[param.name].symbol}
                        durationFilter={durationFilter}
                        state={graphData.state}
                      />
                    </ErrorBoundary>
                  </Card.Section>
                </Card>
              ) : apiToCall[param.name].graph === 'bar' ? (
                <Card>
                  <Card.Section>
                    <ErrorBoundary fallback={<BarChart theme="Light" data={[]} state="Error" />}>
                      <DefaultBarGraph
                        data={graphData.data}
                        name={apiToCall[param.name].name}
                        xAxisValue={apiToCall[param.name].xAxisValue}
                        yAxisValue={apiToCall[param.name].yAxisValue}
                        param={param}
                        state={graphData.state}
                      />
                    </ErrorBoundary>
                  </Card.Section>
                </Card>
              ) : (
                <></>
              )}
            </Box>
          </>
        )}
        <Box paddingBlockEnd={'4'}>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <DefaultIndexTable
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortExceptions={[...apiToCall[param.name].sortExecptions]}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              select={false}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              page={page}
              setPage={setPage}
              resourceName={{
                singular: 'metrics',
                plural: 'metrics',
              }}
              columns={apiToCall[param.name].columns}
              selectedFilters={[]}
              totalRows={tableData.data.length ? tableData.data[0].totalRows : 0}
              tableData={tableData.data}
              durationFilter={durationFilter}
              isCohortReport={isCohortReport}
              setSelectedDateRangeDates={isCohortReport ? setSelectedDateRangeDates : null}
              setResultType={setResultType}
              state={tableData.state}
              param={param}
            />
          </ErrorBoundary>
        </Box>
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
        <DownloadModal
          isOpen={openDownloadModal}
          toggle={() => setOpenDownloadModal(!openDownloadModal)}
          handleDownload={handleDownload}
        />
      </Page>
    </>
  );
};

export default ReportPage;
