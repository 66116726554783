import {customers} from '../api/customerProfile';
import {products} from '../api/productProfile';
import {orders} from '../api/orders';
import {
  aov,
  bestSellers,
  cityWise,
  countryWise,
  grossRevenue,
  provinceWise,
  netRevenue,
  itemsPerOrder,
  returnRevenue,
  costOfGoodsSold,
  firstOrderValueOfRepeatCustomers,
  discountCodeUsage,
  discounts,
  lifetimeValue,
  repeatCustomers,
  getCohortReport,
  revenueBySource,
  rfmAnalysis,
  abandonedCheckouts,
  topCouponCodes,
  topItemsSold,
  ltvTable,
  mostAbandonedProductsTable,
  productNps,
  pincodeWise,
  marketingDashboard,
  getCompareCohortReport,
  dailySummary,
  profitAndLossStatement,
  productsOutOfStock,
  productsAtRisk,
  rfmRepeat,
  rfmAtrisk,
  droppedSales,
  cartRecovery,
} from '../api/reports';
import {
  abandonedCheckoutsColumns,
  allOrdersColumns,
  averageItemsPerOrderColumns,
  averageOrderValueColumns,
  bestSellersColumns,
  cityWiseColumns,
  cogsColumns,
  countryWiseColumns,
  customersColumns,
  discountCodeUsageColumns,
  discountsColumns,
  firstOrderValueOfRepeatCustomersColumns,
  grossRevenueColumns,
  lifeTimeValueColumns,
  netRevenueColumns,
  productsColumns,
  provinceWiseColumns,
  repeatCustomersColumns,
  returnsColumns,
  revenueBySourceColumns,
  rfmAnalysisColumns,
  npsProductColumns,
  pincodeWiseColumns,
  productCostColumns,
  fixedCostColumns,
  pAndLColumns,
  marketingDataColumns,
  droppedSalesColumns,
  cartRecoveryColumns,
} from '../pages/metrics/columns';
import {formatAmount, formatDate, formatDecimalNumber} from './format';
import {fixedCost, productCost} from '../api/cost';
import {LocalStorageService} from '../service/localStorage';
import {pAndL} from '../api/p&l';
const userDetails = LocalStorageService.getUserOrg();
const timeZone = userDetails?.iana_timezone;

const apiToCall = {
  'best-sellers': {
    api: bestSellers,
    sortExecptions: ['Product image'],
    sortBy: 'netRevenue',
    sourceFilter: true,
    sortOrder: 'desc',
    title: 'Best sellers',
    graph: 'bar',
    name: 'Net revenue',
    xAxisValue: 'skuTitle',
    yAxisValue: 'netRevenue',
    symbol: '₹',
    columns: bestSellersColumns,
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
    tooltipOptions: {
      keyFormatter: value => {
        return `${value}`;
      },
      valueFormatter: value => {
        return formatAmount({value, notation: 'compact'});
      },
    },
  },
  'gross-revenue': {
    title: 'Gross revenue',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    isSearchDisabled: true,
    sourceFilter: true,
    api: grossRevenue,
    graph: 'line',
    rfmSegmentation: true,
    xAxisValue: 'date',
    yAxisValue: 'grossRevenue',
    columns: grossRevenueColumns,
    symbol: '₹',
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
    taskText: 'Gross revenue over time',
  },
  'avg-order-value': {
    title: 'Average order value',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    isSearchDisabled: true,
    graph: 'line',
    rfmSegmentation: true,
    sourceFilter: true,
    api: aov,
    xAxisValue: 'date',
    yAxisValue: 'AOV',
    symbol: '₹',
    columns: averageOrderValueColumns,
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
    taskText: 'AOV over time',
  },
  'net-revenue': {
    title: 'Net revenue',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    isSearchDisabled: true,
    api: netRevenue,
    sourceFilter: true,
    graph: 'line',
    rfmSegmentation: true,
    xAxisValue: 'date',
    yAxisValue: 'netRevenue',
    columns: netRevenueColumns,
    symbol: '₹',
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
    taskText: 'Net revenue over time',
  },
  'country-wise-orders': {
    api: countryWise,
    sortExecptions: [],
    sortBy: 'netOrderCount',
    sortOrder: 'desc',
    title: 'Country wise orders',
    graph: 'bar',
    rfmSegmentation: true,
    sourceFilter: true,
    name: 'Net orders',
    xAxisValue: 'country',
    yAxisValue: 'netOrderCount',
    columns: countryWiseColumns,
  },
  'province-wise-orders': {
    api: provinceWise,
    sortExecptions: [],
    sortBy: 'netOrderCount',
    sortOrder: 'desc',
    title: 'Province wise orders',
    graph: 'bar',
    rfmSegmentation: true,
    sourceFilter: true,
    name: 'Net orders',
    xAxisValue: 'province',
    yAxisValue: 'netOrderCount',
    columns: provinceWiseColumns,
  },
  'city-wise-orders': {
    api: cityWise,
    sortExecptions: [],
    sortBy: 'netOrderCount',
    sortOrder: 'desc',
    title: 'City wise orders',
    graph: 'bar',
    rfmSegmentation: true,
    sourceFilter: true,
    name: 'Net orders',
    xAxisValue: 'city',
    yAxisValue: 'netOrderCount',
    columns: cityWiseColumns,
  },
  'pincode-wise-orders': {
    api: pincodeWise,
    sortExecptions: [],
    sortBy: 'netOrderCount',
    sortOrder: 'desc',
    title: 'Pincode wise orders',
    graph: 'bar',
    rfmSegmentation: true,
    sourceFilter: true,
    name: 'Net orders',
    xAxisValue: 'pincode',
    yAxisValue: 'netOrderCount',
    columns: pincodeWiseColumns,
  },
  'items-per-order': {
    title: 'Items per order',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    isSearchDisabled: true,
    graph: 'line',
    rfmSegmentation: true,
    api: itemsPerOrder,
    sourceFilter: true,
    xAxisValue: 'date',
    columns: averageItemsPerOrderColumns,
    yAxisValue: 'averageItemsPerOrder',
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    taskText: 'Items sales per order',
  },
  'discount-code-usage': {
    title: 'Discount code usage',
    sortBy: 'ordersCount',
    sortOrder: 'desc',
    sortExecptions: [],
    api: discountCodeUsage,
    graph: 'bar',
    columns: discountCodeUsageColumns,
    rfmSegmentation: true,
    sourceFilter: true,
    xAxisValue: 'discountCode',
    yAxisValue: 'ordersCount',
    name: 'Orders count',
    isSnapshotSpecial: true,
    titleSnapshot: 'Top coupon codes',
    snapshotSpecialApi: topCouponCodes,
    snapshotXAxis: 'code',
    snapshotYAxis: 'quantity',
  },
  discounts: {
    title: 'Discounts',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    isSearchDisabled: true,
    sourceFilter: true,
    api: discounts,
    graph: 'line',
    rfmSegmentation: true,
    symbol: '₹',
    xAxisValue: 'date',
    yAxisValue: 'totalDiscount',
    columns: discountsColumns,
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
    taskText: 'Discounts over time',
  },
  'lifetime-value': {
    title: 'Lifetime value',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    isSearchDisabled: true,
    api: lifetimeValue,
    graph: 'line',
    rfmSegmentation: true,
    sourceFilter: true,
    symbol: '₹',
    xAxisValue: 'date',
    yAxisValue: 'lifetimeValue',
    columns: lifeTimeValueColumns,
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
    isSnapshotSpecial: true,
    titleSnapshot: 'LTV',

    snapshotSpecialApi: ltvTable,
    snapshotXAxis: 'ltv',
    snapshotYAxis: 'amount',
  },
  'repeat-customers': {
    title: 'Repeat customer revenue',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    isSearchDisabled: true,
    sourceFilter: true,
    api: repeatCustomers,
    graph: 'line',
    rfmSegmentation: true,
    xAxisValue: 'date',
    yAxisValue: 'revenueFromRepeatedCustomers',
    columns: repeatCustomersColumns,
    symbol: '₹',

    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
    taskText: 'Revenue from repeat customers over time',
  },
  'customer-cohort': {
    title: 'Customer cohort',
    sortBy: 'Date',
    sortOrder: 'asc',
    sortExecptions: [],
    api: getCohortReport,
    graph: 'line',
    cohort: 'userCohort',
    durationFilter: 'monthly',
    columns: [],
  },
  'revenue-cohort': {
    title: 'Revenue cohort',
    sortBy: 'Date',
    sortOrder: 'asc',
    sortExecptions: [],
    api: getCohortReport,
    graph: 'line',
    cohort: 'revenueCohort',
    durationFilter: 'monthly',
    columns: [],
  },
  'ltv-cohort': {
    title: 'LTV cohort',
    sortBy: 'Date',
    sortOrder: 'asc',
    sortExecptions: [],
    api: getCohortReport,
    graph: 'line',
    cohort: 'ltvCohort',
    durationFilter: 'monthly',
    columns: [],
  },
  'compare-cohort': {
    title: 'LTV cohort',
    sortBy: 'Date',
    sortOrder: 'asc',
    sortExecptions: [],
    api: getCompareCohortReport,
    graph: 'line',
    cohort: 'compareCohort',
    durationFilter: 'monthly',
    columns: [],
  },
  returns: {
    title: 'Returns',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    graph: 'line',
    rfmSegmentation: true,
    sourceFilter: true,
    api: returnRevenue,
    xAxisValue: 'date',
    yAxisValue: 'returnRate',
    columns: returnsColumns,
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatDecimalNumber({value}) + '%';
      },
    },
    tooltipOptions: {
      titleFormatter: value => {
        return `${formatDate({
          value: new Date(value),
          removeTime: true,
        })}`;
      },
      keyFormatter: value => {
        return `${value}`;
      },
      valueFormatter: value => {
        return formatDecimalNumber({value, notation: 'compact'}) + '%';
      },
    },
    taskText: 'Return rate over time',
  },
  'cost-of-goods-sold': {
    title: 'Cost of goods sold',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    isSearchDisabled: true,
    sourceFilter: true,
    graph: 'line',
    api: costOfGoodsSold,
    xAxisValue: 'date',
    yAxisValue: 'finalCogs',
    columns: cogsColumns,
    symbol: '₹',

    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
    taskText: 'COGS over time',
  },
  'first-order-value-of-repeat-customers': {
    title: 'First order value of repeat customers',
    sortBy: 'date',
    sortOrder: 'asc',
    sortExecptions: [],
    isSearchDisabled: true,
    sourceFilter: true,
    graph: 'line',
    rfmSegmentation: true,
    api: firstOrderValueOfRepeatCustomers,
    xAxisValue: 'date',
    yAxisValue: 'firstOrderValueOfRepeatCustomers',
    symbol: '₹',
    columns: firstOrderValueOfRepeatCustomersColumns,

    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
    taskText: 'First order sales over time',
  },
  customers: {
    title: 'Customers',
    sortBy: 'LTV',
    sortOrder: 'desc',
    sortExecptions: [],
    graph: 'line',
    api: customers,
    xAxisValue: 'date',
    yAxisValue: 'customers',
    columns: customersColumns,

    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return new Intl.NumberFormat('en-US', {
          notation: 'compact',
        }).format(value);
      },
    },
    taskText: 'Customers over time',
  },
  products: {
    title: 'Products',
    sortBy: 'netItemsSold',
    sortOrder: 'desc',
    sortExecptions: [],
    graph: 'line',
    api: products,
    xAxisValue: 'Date',
    yAxisValue: 'products',
    columns: productsColumns,
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return new Intl.NumberFormat('en-US', {
          notation: 'compact',
        }).format(value);
      },
    },
    taskText: 'Products over time',
  },
  'revenue-by-source': {
    title: 'Revenue by source',
    sortBy: 'netRevenue',
    sortOrder: 'asc',
    sortExecptions: [],
    graph: 'bar',
    rfmSegmentation: true,
    api: revenueBySource,
    symbol: '₹',
    xAxisValue: 'sourceName',
    yAxisValue: 'netRevenue',
    name: 'Net revenue',
    columns: revenueBySourceColumns,
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
  },
  'rfm-analysis': {
    title: 'RFM analysis',
    sortBy: 'rfmCategory',
    sortOrder: 'asc',
    sortExecptions: [],
    graph: 'bar',
    api: rfmAnalysis,
    xAxisValue: 'rfmCategory',
    yAxisValue: 'customersCount',
    name: 'RFM Category',
    columns: rfmAnalysisColumns,
    needDashboardInfoText: true,
  },
  'abandoned-checkouts': {
    title: 'Abandoned checkouts',
    sortBy: 'date',
    sortOrder: 'desc',
    sortExecptions: [],
    isSearchDisabled: true,
    graph: 'line',
    rfmSegmentation: true,
    sourceFilter: true,
    api: abandonedCheckouts,
    columns: abandonedCheckoutsColumns,
    xAxisValue: 'date',
    yAxisValue: 'cartValue',
    name: 'Abandoned Checkouts',
    snapshotXAxis: 'sku',
    snapshotYAxis: 'quantity',
    noInfoTab: true,
    symbol: '₹',
    taskText: 'Abandoned cart value over time',
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
  },

  'top-items-sold': {
    title: 'Top items sold',
    sortBy: 'quantity',
    sortOrder: 'desc',
    sortExecptions: [],
    api: topItemsSold,
    graph: 'table',
    xAxisValue: 'sku',
    yAxisValue: 'quantity',
  },
  'orders-of-customer': {
    xAxisValue: 'key',
    yAxisValue: 'value',
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return new Intl.NumberFormat('en-US').format(value);
      },
    },
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
  },
  'product-nps': {
    xAxisValue: 'key',
    yAxisValue: 'value',
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return new Intl.NumberFormat('en-US').format(value);
      },
    },
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
  },
  'ltv-of-customer': {
    xAxisValue: 'key',
    yAxisValue: 'value',
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },

    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
  },
  orders: {
    title: 'Orders',
    sortBy: 'date',
    sortOrder: 'desc',
    sortExecptions: [],
    graph: 'line',
    api: orders,
    xAxisValue: 'date',
    yAxisValue: 'orders',
    columns: allOrdersColumns,

    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.includes('week')) {
          return value;
        }
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return new Intl.NumberFormat('en-US', {
          notation: 'compact',
        }).format(value);
      },
    },
  },

  'nps-product': {
    title: 'Product NPS',
    sortBy: 'date',
    sortOrder: 'desc',
    sortExecptions: [],
    isSearchDisabled: true,
    api: productNps,
    sourceFilter: true,
    graph: 'line',
    rfmSegmentation: true,
    xAxisValue: 'date',
    yAxisValue: 'nps',
    columns: npsProductColumns,
    noInfoTab: true,
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
  },

  productCost: {
    title: 'Product costs',
    api: productCost,
    columns: productCostColumns,
    sortBy: 'price',
    sortOrder: 'desc',
    sortExecptions: [],
  },
  fixedCost: {
    title: 'Fixed costs',
    api: fixedCost,
    columns: fixedCostColumns,
    isSearchDisabled: true,
  },
  'p&l': {
    title: 'P&L Report',
    api: pAndL,
    columns: pAndLColumns,
    isSearchDisabled: true,
    sortBy: 'date',
    sortOrder: 'asc',
    durationFilter: 'monthly',
    graph: 'line',
    xAxisValue: 'month',
    yAxisValue: 'netProfit',
    noInfoTab: true,
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatAmount({value, notation: 'compact'});
      },
    },
  },
  'marketing-dashboard': {
    title: 'Marketing Dashboard',
    api: marketingDashboard,
    columns: marketingDataColumns,
    sortBy: 'date',
    sortOrder: 'desc',
    durationFilter: 'daily',
    graph: 'line',
    xAxisValue: 'date',
    yAxisValue: 'blendedRoas',
    symbol: '₹',
    taskText: 'Blended ROAS over time',
    xAxisOptions: {
      labelFormatter: function formatLinearXAxisLabel(value) {
        if (value.length > 11) {
          value = value + ':00:00';
        }

        let options = {};
        if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
          options = {
            month: 'short',
            day: 'numeric',
            timeZone: timeZone,
          };
        }
        if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
          options = {
            year: 'numeric',
            month: 'short',
            timeZone: timeZone,
          };
        }

        if (value.includes('T')) {
          options = {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone,
          };
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
      },
    },
    yAxisOptions: {
      labelFormatter: function formatLinearYAxisLabel(value) {
        return formatDecimalNumber({value, notation: 'compact'});
      },
    },
  },
  'daily-summary': {
    title: 'Daily Summary',
    api: dailySummary,
    columns: marketingDataColumns,
    sortBy: 'date',
    sortOrder: 'asc',
    durationFilter: 'daily',
    sortExecptions: [],
    selectedDateRange: 'yesterday',
    description: "Your brand's key metrics",
    redirectUrl: '/dashboard',
  },
  'pnl-statement': {
    title: 'Profit and Loss Statement',
    api: profitAndLossStatement,
    columns: marketingDataColumns,
    sortBy: 'date',
    sortOrder: 'asc',
    durationFilter: 'daily',
    sortExecptions: [],
    selectedDateRange: 'yesterday',
    description: 'Your detailed profit and loss statement',
    redirectUrl: '/pnl',
  },
  'products-out-of-stock': {
    title: 'Products out of stock',
    api: productsOutOfStock,
    columns: marketingDataColumns,
    sortBy: 'date',
    sortOrder: 'asc',
    durationFilter: 'daily',
    sortExecptions: [],
    selectedDateRange: 'lastNinetyDays',
    description: 'All your SKUs sold within the last 90 days currently out of stock',
    redirectUrl: '/product-module?tab=outOfStockAndLostSales',
  },
  'products-at-risk': {
    title: 'Products At-risk',
    api: productsAtRisk,
    columns: marketingDataColumns,
    sortBy: 'date',
    sortOrder: 'asc',
    durationFilter: 'daily',
    sortExecptions: [],
    selectedDateRange: 'yesterday',
    description: 'Your top selling products at risk of going out of stock soon',
    redirectUrl: '/product-module?tab=atRisk',
  },
  'rfm-repeat': {
    title: 'RFM Repeat',
    api: rfmRepeat,
    columns: marketingDataColumns,
    sortBy: 'date',
    sortOrder: 'asc',
    durationFilter: 'daily',
    sortExecptions: [],
    selectedDateRange: 'yesterday',
    description: 'Customers to be targeted for repeat purchases',
  },
  'rfm-at-risk': {
    title: 'RFM At-risk',
    api: rfmAtrisk,
    columns: marketingDataColumns,
    sortBy: 'date',
    sortOrder: 'asc',
    durationFilter: 'daily',
    sortExecptions: [],
    selectedDateRange: 'yesterday',
    description: 'Quality customers at risk of losing touch with your brand',
  },
  'dropped-sales': {
    title: 'Dropped Sales',
    api: droppedSales,
    columns: droppedSalesColumns,
    sortBy: 'cancelledOrderDate',
    sortOrder: 'asc',
    durationFilter: 'daily',
    sortExecptions: [],
    selectedDateRange: 'yesterday',
    description: 'Customers who have cancelled their orders without reordeing',
    noInfoTab: true,
    noGraph: true,
  },
  'cart-recovery': {
    title: 'Cart Recovery',
    api: cartRecovery,
    columns: cartRecoveryColumns,
    sortBy: 'abandonedCartDate',
    sortOrder: 'asc',
    durationFilter: 'daily',
    sortExecptions: [],
    selectedDateRange: 'yesterday',
    description: 'High value customers who need a final nudge',
    noInfoTab: true,
    noGraph: true,
  },
};

export default apiToCall;
