import {Icon, Loading, Navigation} from '@shopify/polaris';
import React, {useRef, useState} from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import {
  ReportsMajor,
  InsertDynamicSourceMinor,
  ProfileMajor,
  AppsMajor,
  ProductsMajor,
  AnalyticsMajor,
  PopularMajor,
  FinancesMajor,
  ProductsMinor,
  OrdersMajor,
  MarketingMinor,
  ChatMajor,
  NotificationMajor,
  BankMajor,
  MetafieldsMajor,
} from '@shopify/polaris-icons';
import {useLocation, useNavigate, Link} from 'react-router-dom';
import {usePreventNavigateStore} from '../app/store';
import {LeavePageModal} from './modal';
import Box from '../lib/Box';
import config from '../config';
import {LocalStorageService} from '../service/localStorage';

function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const protect = usePreventNavigateStore(state => state.protect);
  const [openLeavePageModal, setOpenLeavePageModal] = useState(false);
  const [toNavigate, setToNavigate] = useState('');
  const [openSupportChatBox, setOpenSupportChatBox] = useState(false);
  const [supportChatBoxStatus, setSupportChatBoxStatus] = useState('Success');
  const tawkMessengerRef = useRef();

  const handleMaximize = () => {
    if (!openSupportChatBox) {
      setSupportChatBoxStatus('Loading');
      setOpenSupportChatBox(true);
    }
  };
  const onLoad = () => {
    setSupportChatBoxStatus('Success');
    tawkMessengerRef.current.maximize();
  };

  const MenuItem = ({item, isSubNavigationMenu}) => {
    const [itemOption, setItemOption] = useState(item);
    return (
      <div className={`navigationMenuContainer${isSubNavigationMenu ? '_subMenu' : ''}`}>
        {itemOption.subNavigationMenu ? (
          <div
            className={`navigationMenu`}
            onClick={() => {
              setItemOption({
                ...itemOption,
                subNavigationMenu: itemOption.subNavigationMenu.map(subItem => ({
                  ...subItem,
                  enabled: !subItem.enabled,
                })),
              });
            }}
          >
            <Box as="div" className="navigationMenuImage">
              <Icon source={itemOption.icon} color={'base'} />
            </Box>
            <Box as="div" className="navigationMenuText">
              {itemOption.title}
            </Box>
          </div>
        ) : itemOption.title === 'Support' ? (
          <div className={`navigationMenu`} onClick={itemOption.onClick}>
            <Box as="div" className="navigationMenuImage">
              <Icon source={itemOption.icon} color={'base'} />
            </Box>
            <Box as="div" className="navigationMenuText">
              {itemOption.title}
            </Box>
          </div>
        ) : (
          <Link
            as="div"
            to={itemOption.path}
            className={itemOption.condition ? `navigationMenuOnClicked` : `navigationMenu`}
            onClick={event => {
              if (protect) {
                setOpenLeavePageModal(true);
                event.preventDefault();
                setToNavigate(itemOption.path);
              } else {
                event.preventDefault();
                navigate(itemOption.path);
              }
            }}
          >
            <Box as="div" className="navigationMenuImage">
              <Icon source={itemOption.icon} color={itemOption.condition ? 'primary' : 'base'} />
            </Box>
            <Box as="div" className="navigationMenuText">
              {itemOption.title}
            </Box>
          </Link>
        )}
        {itemOption.subNavigationMenu &&
          itemOption.subNavigationMenu.map((subItem, i) => {
            if (subItem.enabled) {
              return <MenuItem key={i} item={subItem} isSubNavigationMenu={true} />;
            } else return '';
          })}
      </div>
    );
  };
  let navigationMenu = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      condition: location.pathname === '/dashboard',
      icon: AnalyticsMajor,
    },
    {
      title: 'Metrics',
      path: '/metrics',
      condition: location.pathname === '/metrics',
      icon: ReportsMajor,
    },
    // {
    //   title: 'Signals',
    //   path: '/active-signals',
    //   condition:
    //     location.pathname.split('/')[1] === 'signal-new' ||
    //     location.pathname === '/active-signals' ||
    //     location.pathname === '/signal-history' ||
    //     location.pathname.split('/')[1] === 'signal',
    //   icon: InsertDynamicSourceMinor,
    // },
    // {
    //   title: 'Alerts',
    //   path: '/alerts-and-reports',
    //   condition:
    //     location.pathname.split('/')[1] === 'alert-new' ||
    //     location.pathname === '/active-alerts' ||
    //     location.pathname === '/alert-history' ||
    //     location.pathname.split('/')[1] === 'alert',
    //   icon: NotificationMajor,
    // },
    {
      title: 'Segments',
      path: '/segments',
      condition:
        location.pathname === '/segments' ||
        location.pathname === '/segment-new' ||
        location.pathname === '/segment',
      icon: AppsMajor,
    },
    {
      title: 'Orders',
      path: '/orders',
      condition: location.pathname === '/orders',
      icon: OrdersMajor,
    },
    {
      title: 'Customers',
      path: '/customers',
      condition: location.pathname === '/customers',
      icon: ProfileMajor,
    },
    // {
    //   title: 'Products',
    //   path: '/product',
    //   condition: location.pathname === '/products',
    //   icon: ProductsMajor,
    // },
    // {
    //   title: 'Pricing',
    //   path: '/pricing',
    //   condition: location.pathname === '/pricing',
    //   icon: FinancesMajor,
    // },
    {
      title: 'LTV',
      path: '/ltv',
      condition: location.pathname === '/ltv',
      icon: PopularMajor,
      subNavigationMenu: [
        {
          title: 'Cohorts',
          path: '/ltv',
          enabled: ['/ltv', '/compare-cohort'].includes(location.pathname) ? true : false,
          condition: location.pathname === '/ltv',
          icon: PopularMajor,
        },
        {
          title: 'Compare Cohorts',
          path: '/compare-cohort',
          enabled: ['/ltv', '/compare-cohort'].includes(location.pathname) ? true : false,
          condition: location.pathname === '/compare-cohort',
          icon: PopularMajor,
        },
      ],
    },
    {
      title: 'NPS',
      path: '/nps',
      condition: location.pathname === '/nps',
      icon: InsertDynamicSourceMinor,
    },
    {
      title: 'Profit & Loss ',
      path: '/pnl',
      condition: location.pathname === '/pnl',
      icon: BankMajor,
    },
    {
      title: 'Cost & Expenses',
      path: '/cost',
      condition: location.pathname === '/cost',
      icon: FinancesMajor,
    },
    {
      title: 'Product Journey',
      path: '/product-journey',
      condition: location.pathname === '/product-journey',
      icon: ProductsMinor,
    },
    {
      title: 'Marketing Dashboard',
      path: '/marketing-dashboard',
      condition: location.pathname === '/marketing-dashboard',
      icon: MarketingMinor,
    },
    {
      title: 'Product Groups',
      path: '/product-groups',
      condition:
        location.pathname === '/product-groups' ||
        location.pathname === '/product-groups-new' ||
        location.pathname === '/product-group',
      icon: MetafieldsMajor,
    },
    {
      title: 'Support',
      icon: ChatMajor,
      onClick: handleMaximize,
    },
  ];

  let isAdmin = LocalStorageService.isAdminSignedIn();
  let isGiva = LocalStorageService.getUserOrg().name === 'giva';
  if (isAdmin) {
    navigationMenu.splice(6, 0, {
      title: 'Product module',
      path: '/product-module',
      condition: location.pathname === '/product-module',
      icon: ProductsMajor,
    });
    navigationMenu.splice(2, 0, {
      title: 'Alerts',
      path: '/alerts-and-reports',
      condition:
        location.pathname.split('/')[1] === 'alert-new' ||
        location.pathname === '/active-alerts' ||
        location.pathname === '/alert-history' ||
        location.pathname.split('/')[1] === 'alert',
      icon: NotificationMajor,
    });
  }
  if (isGiva) {
    navigationMenu = navigationMenu.filter(item => {
      return item.title !== 'NPS';
    });
  }

  return (
    <>
      {supportChatBoxStatus === 'Loading' && <Loading />}
      <Navigation location={location.pathname}>
        {navigationMenu.map((item, i) => (
          <MenuItem item={item} key={i} isSubNavigationMenu={false} />
        ))}
      </Navigation>
      {openSupportChatBox && (
        <TawkMessengerReact
          onLoad={onLoad}
          propertyId={config.TAWK_PROPERTY_ID}
          widgetId={config.TAWK_WIDGET_ID}
          ref={tawkMessengerRef}
        />
      )}

      {openLeavePageModal && (
        <LeavePageModal
          currentState={openLeavePageModal}
          toNavigate={toNavigate}
          onClose={setOpenLeavePageModal}
        />
      )}
    </>
  );
}

export default Menu;
