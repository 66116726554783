import {LocalStorageService} from '../service/localStorage';

function isNumber(value) {
  if (value === '') {
    return false;
  }
  return !isNaN(value);
}
const isValidDate = value => {
  if (!value) return false;
  return Boolean(value.getTime());
};
const formatDate = ({value, removeTime, removeMonth, seconds = false}) => {
  if (!isValidDate(value)) return 'NA';
  // const userDetails = LocalStorageService.getUserOrg();
  // const timeZone = userDetails?.iana_timezone;
  let options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    // timeZone: timeZone,
  };
  if (removeTime) {
    delete options.hour;
    delete options.minute;
  }
  if (removeMonth) {
    delete options.day;
  }
  if (!removeTime && seconds) {
    options.second = 'numeric';
  }
  let d = new Intl.DateTimeFormat('en-US', options).format(value);
  if (removeTime) {
    return d;
  }
  return d.slice(0, d.lastIndexOf(',')) + ' at' + d.slice(d.lastIndexOf(',') + 1);
};

const formatAmount = ({value, notation, round}) => {
  const userDetails = LocalStorageService.getUserOrg();
  const currency = userDetails?.currency;
  let options = {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  };
  if (notation) {
    options.notation = notation;
  }
  if (round) {
    // options.notation = 'compact';
    options.maximumFractionDigits = 0;
    return new Intl.NumberFormat('en-US', options).format(Math.round(value / 100));
  }
  return new Intl.NumberFormat('en-US', options).format(value / 100); //converting paisa to rupee(dividing by 100)
};

const formatLtvAmount = value => {
  if (isNumber(value)) {
    const userDetails = LocalStorageService.getUserOrg();
    const currency = userDetails?.currency;
    let options = {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
    };

    options.notation = 'compact';
    options.minimumFractionDigits = 2;
    options.maximumFractionDigits = 2;

    return new Intl.NumberFormat('en-US', options).format(value / 100); //converting paisa to rupee(dividing by 100)
  }
  return '';
};

const formatPercentage = value => {
  if (isNumber(value)) {
    return value + ' %';
  }
  return '';
};

const formatLtvNumber = value => {
  if (isNumber(value)) {
    let options = {};
    options.notation = 'compact';
    return new Intl.NumberFormat('en-US', options).format(isNaN(value) ? 0 : value);
  }
  return '';
};

const convertToRupee = value => {
  const rupeeValue = (value / 100).toFixed(2);
  return rupeeValue;
};

const formatForDownload = data => {
  for (let i = 0; i < data.length; i++) {
    const keys = Object.keys(data[i]);
    for (let j = 0; j < keys.length; j++) {
      if (keys[j] !== 'date') {
        data[i][keys[j]] = convertToRupee(data[i][keys[j]]);
      }
    }
  }
  return data;
};

const formatDecimalNumber = ({value, notation, round}) => {
  let options = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  if (notation) {
    options.notation = notation;
  }
  if (round) {
    options = {
      maximumFractionDigits: 0,
    };
  }
  return new Intl.NumberFormat('en-US', options).format(value);
};

const formatNumber = ({value, notation}) => {
  let options = {};
  if (notation) {
    options.notation = notation;
  }
  return new Intl.NumberFormat('en-US', options).format(isNaN(value) ? 0 : value);
};

const toTitleCase = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};
const formatLinearXAxisLabel = value => {
  const userDetails = LocalStorageService.getUserOrg();
  const timeZone = userDetails?.iana_timezone;
  if (value.length > 11) {
    value = value + ':00:00';
  }

  let options = {};
  if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gm.test(value)) {
    options = {
      month: 'short',
      day: 'numeric',
      timeZone: timeZone,
    };
  }
  if (/^[0-9]{4}-[0-9]{2}$/gm.test(value)) {
    options = {
      year: 'numeric',
      month: 'short',
      timeZone: timeZone,
    };
  }

  if (value.includes('T')) {
    options = {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: timeZone,
    };
  }
  return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
};
export {
  formatDate,
  formatAmount,
  formatNumber,
  formatDecimalNumber,
  toTitleCase,
  formatPercentage,
  formatLtvAmount,
  formatLtvNumber,
  formatLinearXAxisLabel,
  formatForDownload,
};
