import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useDateRangeStore} from '../app/store';
import config from '../config';

const convertDatesToReadableString = dates => {
  function convertToDateString(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  let start = convertToDateString(dates.start);
  let end = convertToDateString(dates.end);

  return {start, end};
};

const stringifyDates = datesObject => JSON.stringify(datesObject);
const parseDates = datesString => {
  if (!datesString) return null;
  let {start, end} = JSON.parse(datesString);
  return {start: new Date(start), end: new Date(end)};
};
const useSearchParamStoreSync = (paramNames, extraParams = []) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const store = useDateRangeStore();
  const paramsFromSearch = searchParams.entries();
  const updateStore = () => {
    let isUpdated = false;
    let isDateUpdated = false;
    let isCompareDateUpdated = false;
    const updates = {};

    for (const [name, value] of paramsFromSearch) {
      if (paramNames.includes(name)) {
        let initValue = store[name];

        if (name === 'selectedDateRangeDates' || name === 'selectedCompareDates') {
          let {start, end} = convertDatesToReadableString(initValue);
          let dates = stringifyDates({start, end});
          if (dates !== value) {
            isUpdated = true;
            if (name === 'selectedDateRangeDates') {
              isDateUpdated = true;
            } else {
              isCompareDateUpdated = true;
            }
          }
          updates[name] = parseDates(value);
        } else {
          let currentValue = value;
          if (currentValue === 'custom') {
            currentValue = undefined;
          }
          if (initValue !== currentValue) {
            isUpdated = true;
          }
          updates[name] = currentValue;
        }
      }
    }
    if (isDateUpdated) {
      updates['selectedDateRange'] = undefined;
    }
    if (isCompareDateUpdated) {
      updates['selectedCompare'] = undefined;
    }
    if (isUpdated) {
      store.update(updates);
    }
  };
  const updateSearchParams = () => {
    const newSearchParams = new URLSearchParams();
    let isUpdated = false;
    paramNames.forEach(name => {
      const value = store[name];
      if (name === 'selectedDateRangeDates' || name === 'selectedCompareDates') {
        let {start, end} = convertDatesToReadableString(value);
        let dates = stringifyDates({start, end});
        if (searchParams.get(name) != dates) {
          isUpdated = true;
        }
        newSearchParams.set(name, dates);
      } else if (name === 'selectedDateRange' || name === 'selectedCompare') {
        let initValue = searchParams.get(name);
        let currentValue = value;
        if (value == undefined) {
          currentValue = 'custom';
        }
        if (initValue != currentValue) {
          isUpdated = true;
        }
        newSearchParams.set(name, currentValue);
      } else {
        if (searchParams.get(name) != value) {
          isUpdated = true;
        }
        newSearchParams.set(name, value);
      }
    });

    extraParams.forEach(({label, value}) => {
      if (searchParams.get(label) != value) {
        isUpdated = true;
      }
      newSearchParams.set(label, value);
    });
    if (isUpdated) {
      setSearchParams(newSearchParams);
    }
  };
  useEffect(() => {
    let isProcessStarted = false;
    const processTimeOut = setTimeout(() => {
      if (!isProcessStarted) {
        updateStore();
      }
    }, config.DEBOUNCING_DELAY);

    return () => {
      isProcessStarted = true;
      clearTimeout(processTimeOut);
    };
  }, [searchParams]);

  // Update search params when store changes
  useEffect(() => {
    let isProcessStarted = false;
    const processTimeOut = setTimeout(() => {
      if (!isProcessStarted) {
        updateSearchParams();
      }
    }, config.DEBOUNCING_DELAY);

    return () => {
      isProcessStarted = true;
      clearTimeout(processTimeOut);
    };
  }, [store]);
};

export default useSearchParamStoreSync;
