import {ActionList, Button, Icon, Inline, Popover, Text} from '@shopify/polaris';
import React, {useCallback, useEffect, useState} from 'react';
import {PlusMinor, CancelMinor} from '@shopify/polaris-icons';
import {
  getLocationList,
  getProductCategoryList,
  getVariantList,
  getdiscountCodeList,
} from '../../../api/filterLists';
import FilterDropDown from '../../../components/FilterDropdown';
import {getTransformedProductTitle} from '../../../features/getTransformedProductTitle';

const getListMap = {
  products: getVariantList,
  discountCodes: getdiscountCodeList,
  defaultCountry: getLocationList,
  defaultCity: getLocationList,
  defaultProvince: getLocationList,
  productCategory: getProductCategoryList,
};
const labelMap = {
  products: 'Product variant',
  discountCodes: 'Discount code',
  defaultCountry: 'Country',
  defaultCity: 'City',
  defaultProvince: 'Province/State',
  productCategory: 'Product category',
};
const cohortVariables = [
  {
    label: 'Product variant',
    value: 'products',
  },
  {
    label: 'Discount code',
    value: 'discountCodes',
  },
  {
    label: 'Product category',
    value: 'productCategory',
  },
  {
    label: 'Country',
    value: 'defaultCountry',
  },
  {
    label: 'City',
    value: 'defaultCity',
  },
  {
    label: 'Province/State',
    value: 'defaultProvince',
  },
];
const AddButton = ({setCohorts, cohorts, state}) => {
  const [active, setActive] = useState(false);
  const [isSelectVariableClicked, setIsSelectVariableClicked] = useState(false);
  const [search, setSearch] = useState('');
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const itemOption = {
    content: 'Select varaiable',
    onAction: handleSelectVariable,
  };
  const [item, setItem] = useState({...itemOption});
  const [label, setLabel] = useState(null);

  function handleSelectVariable() {
    setIsSelectVariableClicked(true);
    setItem({...item, content: <Text fontWeight="bold">{item.content}</Text>});
  }

  const handleSelect = cohortVariable => {
    if (!isSelectVariableClicked) {
      setIsSelectVariableClicked(true);
    }
    setItem({...item, cohortVariable});
  };

  const reset = () => {
    setItem({...itemOption});
    setSelectedItem(null);
    setLabel(null);
    setIsSelectVariableClicked(false);
    setSearch('');
    setItemList([]);
  };

  const toggleActive = useCallback(() => {
    reset();
    return setActive(active => !active);
  }, []);

  useEffect(() => {
    if (selectedItem && label) {
      setCohorts([...cohorts, {cohortVariable: item.cohortVariable, label, value: selectedItem}]);
      reset();
    }
  }, [label]);

  const getVariant = async () => {
    if (selectedItem && item.cohortVariable === 'products') {
      let variantData = await getVariantList({params: {variantIds: selectedItem}});
      let result = variantData;
      return {status: true, data: result.data.result[0]};
    }
    return {status: true, data: {}};
  };

  useEffect(() => {
    if (selectedItem) {
      if (item.cohortVariable === 'products') {
        getVariant().then(response => {
          if (Object.keys(response.data).length > 0) {
            setLabel(getTransformedProductTitle(response.data));
          }
        });
      } else {
        setLabel(selectedItem);
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    if (item.cohortVariable) {
      let params = {};
      if (['defaultCountry', 'defaultCity', 'defaultProvince'].includes(item.cohortVariable)) {
        params.listName = item.cohortVariable;
      }
      if (search) {
        params.search = search;
      }
      getListMap[item.cohortVariable]({params})
        .then(response => {
          setItemList(response.data.result);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [item, search]);

  const activator = (
    <Button onClick={toggleActive} disabled={!state || state === 'Loading'}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Icon source={PlusMinor}></Icon>
        <div style={{paddingLeft: '5px'}}>Add cohort</div>
      </div>
    </Button>
  );
  return (
    <div>
      <Inline>
        <Popover
          active={active}
          activator={activator}
          autofocusTarget="first-node"
          onClose={toggleActive}
        >
          <Inline blockAlign="start">
            <ActionList
              actionRole="menuitem"
              items={[{...item, variant: 'menu'}]}
              sections={
                isSelectVariableClicked
                  ? [
                      {
                        // title: item.content,
                        items: cohortVariables.map(x => ({
                          content: x.label,
                          onAction: () => handleSelect(x.value),
                          active: item.cohortVariable === x.value,
                        })),
                      },
                    ]
                  : []
              }
            />
            {item.cohortVariable && (
              <FilterDropDown
                itemList={itemList}
                setSelectedItem={setSelectedItem}
                setQuery={setSearch}
                query={search}
                itemType={item.cohortVariable}
                label={labelMap[item.cohortVariable]}
                setIsOpen={setActive}
                selectedItem={selectedItem}
                isActivatorNeeded={false}
              />
            )}
          </Inline>
        </Popover>
      </Inline>
    </div>
  );
};
const AddCohort = ({cohorts, setCohorts, state, tableData, setTableData, data, setData}) => {
  const handleRemove = (cohort, length) => {
    console.log(length);
    setCohorts(cohorts.filter(x => x.label !== cohort.label));
    setTableData(
      tableData.filter(x => {
        if (length === 1) {
          return x.cohort !== cohort.label && x.cohort !== 'LTV Average';
        } else {
          return x.cohort !== cohort.label;
        }
      })
    );
    setData({
      ...data,
      data: data.data.filter(x => {
        if (length === 1) {
          return x.cohort !== cohort.label && x.cohort !== 'LTV Average';
        } else {
          return x.cohort !== cohort.label;
        }
      }),
    });
  };
  return (
    <>
      <Inline align="space-between">
        <Inline>
          {cohorts.map((cohort, i) => (
            <>
              <Button onClick={() => handleRemove(cohort, cohorts.length)}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Icon source={CancelMinor}></Icon>
                  <div style={{paddingLeft: '5px'}}>{cohort.label}</div>
                </div>
              </Button>
              {i >= 0 ? <Text>Vs</Text> : ''}
            </>
          ))}
          <AddButton setCohorts={setCohorts} cohorts={cohorts} state={state} />
        </Inline>
        {/* <Button>Save cohorts</Button> */}
      </Inline>
    </>
  );
};

export {AddCohort};
