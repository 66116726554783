import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {convertToOrgTimezone, toClickHouseDate} from '../../../features/convertDates';
import apiToCall from '../../../features/apiToCall';
import Inline from '../../../lib/Inline';
import MultiSelect from '../../../components/multiSelect';
import Page from '../../../components/page';
import useApiCall from '../../../hooks/useApiCall';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import Card from '../../../lib/Card';
import DefaultIndexTable from '../../../components/defaultIndexTable';
import Box from '../../../lib/Box';
const insightLevelValueToLable = {
  ad: 'Ad',
  adSet: 'Ad set',
  campaign: 'Campaign',
};
const MarketingDashboardDurationDetailed = () => {
  const {durationFilter, date} = useParams();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const param = {name: 'marketing-dashboard'};
  const [queryValue, setQueryValue] = useState('');
  const [sortBy, setSortBy] = useState('totalSpend');
  const [sortOrder, setSortOrder] = useState(apiToCall[param.name].sortOrder);
  const [levelSelected, setLevelSelected] = useState('campaign');
  const [channel, setChannel] = useState('');

  const calculateDateRange = () => {
    let startDate, endDate;
    const now = new Date(date);

    switch (durationFilter) {
      case 'daily':
        startDate = new Date(now.setHours(0, 0, 0, 0)); // Start of the day
        endDate = new Date(now.setHours(23, 59, 59, 999)); // End of the day
        break;

      case 'weekly':
        const dayOfWeek = now.getDay(); // Day of the week (0 - Sunday, 6 - Saturday)
        const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - daysToMonday);
        startOfWeek.setHours(0, 0, 0, 0);

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999);

        startDate = startOfWeek;
        endDate = endOfWeek;
        break;

      case 'monthly':
        const year = now.getFullYear();
        const month = now.getMonth(); // 0-based index for months
        startDate = new Date(year, month, 1, 0, 0, 0, 0); // First day of the month
        endDate = new Date(year, month + 1, 0, 23, 59, 59, 999); // Last day of the month
        break;

      default:
        console.error('Invalid durationFilter:', durationFilter);
        return;
    }
    return {startDate, endDate};
  };
  const fetchMarketingData = async () => {
    let {startDate, endDate} = calculateDateRange();

    let convertedStartDate = convertToOrgTimezone(toClickHouseDate(startDate, 'start'));
    let convertedEndDate = convertToOrgTimezone(toClickHouseDate(endDate, 'end'));
    let params = {
      startDate: convertedStartDate,
      endDate: convertedEndDate,
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'table',
      insightLevel: levelSelected,
      durationFilter: 'total',
      channel,
    };
    let response = await apiToCall[param.name].api({params});
    return response.data;
  };

  const marketingDashboardApiDependencies = [
    page,
    queryValue,
    sortBy,
    sortOrder,
    levelSelected,
    durationFilter,
    channel,
    date,
  ];
  const marketingData = useApiCall(fetchMarketingData, marketingDashboardApiDependencies);

  return (
    <Page
      title={date}
      breadcrumbs={[
        {
          content: 'customer',
          onAction: () => {
            navigate(-1);
          },
        },
      ]}
    >
      <Box paddingBlockEnd={'4'} paddingBlockStart={'4'}>
        <Inline>
          <MultiSelect
            title="Level"
            options={[
              {label: 'Ad', value: 'ad'},
              {label: 'Ad set', value: 'adSet'},
              {label: 'Campaign', value: 'campaign'},
            ]}
            selected={levelSelected}
            setSelected={setLevelSelected}
            disableMultiple={true}
            setPage={setPage}
            setChecked={() => {}}
          />

          <MultiSelect
            title="Channel"
            options={[
              {label: 'All', value: ''},
              {label: 'Google Ads', value: 'googleAds'},
              {label: 'Facebook Ads', value: 'facebookAds'},
            ]}
            selected={channel}
            setSelected={setChannel}
            disableMultiple={true}
            setPage={setPage}
            setChecked={() => {}}
          />
        </Inline>
      </Box>
      <Box paddingBlockEnd={'4'}>
        <ErrorBoundary
          fallback={
            <Card>
              <Card.Section>
                <div>An error has occured</div>
              </Card.Section>
            </Card>
          }
        >
          <DefaultIndexTable
            select={false}
            page={page}
            setPage={setPage}
            resourceName={{
              singular: 'metrics',
              plural: 'metrics',
            }}
            columns={apiToCall['marketing-dashboard'].columns
              .filter(column => {
                if (
                  column.value === 'date' ||
                  column.value === 'blendedROAS' ||
                  column.value === 'totalRevenue'
                ) {
                  return false;
                }
                return true;
              })
              .map(column =>
                column.value === 'insightLevelName'
                  ? {
                      ...column,
                      title: column.title.replace(
                        '{insightLevel}',
                        insightLevelValueToLable[levelSelected]
                      ),
                    }
                  : column
              )}
            selectedFilters={[]}
            totalRows={marketingData.data.length > 0 ? marketingData.data[0].totalRows : 0}
            tableData={marketingData.data}
            setSelectedDateRangeDates={() => {}}
            state={marketingData.state}
            param={param}
            disableSort={false}
            isSearchEnabled={true}
            sortBy={sortBy}
            sortOrder={sortOrder}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            queryValue={queryValue}
            setQueryValue={setQueryValue}
            durationFilter={durationFilter}
          />
        </ErrorBoundary>
      </Box>
    </Page>
  );
};

export default MarketingDashboardDurationDetailed;
