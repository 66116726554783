import React, {useCallback, useEffect, useState} from 'react';
import {LightenDarkenColor} from 'lighten-darken-color';
import {
  IndexTable,
  Popover,
  ChoiceList,
  TextContainer,
  Pagination,
  Link,
  EmptySearchResult,
  Badge,
} from '@shopify/polaris';
import Card from '../lib/Card';
import Box from '../lib/Box';
import Inline from '../lib/Inline';
import {StarOutlineMinor, SortMinor, ArrowUpMinor, ArrowDownMinor} from '@shopify/polaris-icons';
import {Link as RouterLink} from 'react-router-dom';
import {
  formatAmount,
  formatDecimalNumber,
  formatNumber,
  formatDate,
  formatLtvAmount,
} from '../features/format';
import TableFilters from './tableFilters';
// import {useNavigate} from 'react-router-dom';
import apiToCall from '../features/apiToCall';
import BasicFilter from '../pages/ltv/filters';
import {getMinAndMax} from '../pages/ltv/getMinAndMaxValue';
import {assignColorLightenValue} from '../pages/ltv/assignColorLightenValue';
import ColumnHeading from './ColumnHeading';
import {LocalStorageService} from '../service/localStorage';
import {verifyInventory} from '../features/helper';
import TableColumnValue from './TableColumnValue';
import {AddCohort} from '../pages/ltv/compareCohort/AddCohort';
import Button from '../lib/Button';
import Stack from '../lib/Stack';
import Icon from '../lib/Icon';
import Text from '../lib/Text';

const assignConditionalClasses = (param, data, col) => {
  const conditionalClasses = [];

  switch (param?.name) {
    case 'orders':
      if (data.isCancelled === 'Yes') {
        conditionalClasses.push('strike-and-fade');
      }

      break;

    default:
  }

  return conditionalClasses;
};

const DefaultIndexTable = ({
  needSaveFilters,
  valueTypes = {},
  disableSort,
  actions,
  columns,
  tableData,
  selectedFilters,
  onRowClick,
  resourceName,
  page,
  setPage,
  totalRows,
  queryValue,
  setQueryValue,
  select,
  sortOrder,
  setSortOrder,
  sortBy,
  setSortBy,
  durationFilter,
  isCohortReport,
  cacOn = false,
  state,
  param,
  queryPlaceholder,
  setSelected,
  isSearchEnabled,
  filters = [],
  cumulative,
  onChange,
  formatFunction,
  isCompareCohort,
  cohorts,
  setCohorts,
  setTableData,
  data,
  setData,
  isFixedCostReport,
  resultType,
}) => {
  const [colHeading, setColHeading] = useState([{title: ''}]);
  const [sortChoices, setSortChoices] = useState([{label: '', value: ''}]);
  const userDetails = LocalStorageService.getUserOrg();
  const timeZone = userDetails?.iana_timezone;
  isSearchEnabled =
    (param &&
      param.name &&
      (param.name === 'segments' || !apiToCall[param.name].isSearchDisabled)) ||
    isSearchEnabled;

  const dateColumnSelector = {
    hourly: 'Hour',
    daily: 'Day',
    weekly: 'Start of week',
    monthly: 'Month',
    quarterly: 'Quarter',
  };

  const setDate = (value, type) => {
    let options = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: timeZone,
    };
    if (type === 'weekly') {
      options = {
        month: 'short',
        day: 'numeric',
        timeZone: timeZone,
      };
    }
    if (type === 'daily') {
      options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: timeZone,
      };
    }
    if (type === 'monthly') {
      options = {
        year: 'numeric',
        month: 'short',
        timeZone: timeZone,
      };
    }

    return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
  };

  const valueType = {
    image: (data, col) => {
      return (
        <Box>
          <img
            alt={'Not found'}
            width={'60px'}
            height={'60px'}
            src={
              data[col.value] ||
              'https://user-images.githubusercontent.com/133949320/244652984-236bc15c-cc91-4ac0-ba33-1333c0d3ae5c.png'
            }
          />
        </Box>
      );
    },
    amount: (data, col) => {
      return <Inline align="end">{formatAmount({value: data[col.value]})}</Inline>;
    },
    decimal: (data, col) => {
      return <Inline align="end">{formatDecimalNumber({value: data[col.value]})}</Inline>;
    },
    number: (data, col) => {
      return <Inline align="end">{formatNumber({value: data[col.value]})}</Inline>;
    },
    percent: (data, col) => {
      return <Inline align="end">{formatDecimalNumber({value: data[col.value]}) + ' %'}</Inline>;
    },
    date: (data, col) => {
      return data[col.value] ? (
        <Inline align="end">
          {formatDate({value: new Date(data[col.value]), removeTime: true})}
        </Inline>
      ) : (
        <Inline align="center">-</Inline>
      );
    },
    datetime: (data, col) => {
      return data[col.value] ? (
        <Inline align="end">{formatDate({value: new Date(data[col.value])})}</Inline>
      ) : (
        <Inline align="center">-</Inline>
      );
    },
    nestedDate: (data, col) => {
      const properties = col.value.split('.');
      let result = data;

      for (let prop of properties) {
        result = result[prop];
      }
      return <Inline align="end">{formatDate({value: new Date(result), removeTime: true})}</Inline>;
    },
    nestedValue: (data, col) => {
      const properties = col.value.split('.');
      let result = data;

      for (let prop of properties) {
        result = result[prop];
      }
      return <Inline align="end">{result}</Inline>;
    },
    customerID: (data, col) => {
      return !data['customerID'] ? (
        'Guest User'
      ) : (
        <RouterLink style={{textDecoration: 'none'}} to={`/customers/${data['customerID']}`}>
          {data[col.value]?.trim() ? data[col.value] : 'No name'}
        </RouterLink>
      );
    },
    checkoutURL: (data, col) => {
      return (
        <Link url={data[col.value]} external removeUnderline>
          Checkout Cart URL
        </Link>
      );
    },
    variantID: (data, col) => {
      return (
        <RouterLink style={{textDecoration: 'none'}} to={`/product/${data['variantId']}`}>
          {data[col.value]}{' '}
        </RouterLink>
      );
    },
    jobID: (data, col) => {
      return (
        <RouterLink style={{textDecoration: 'none'}} to={`/admin/jobs/${data['_id']}`}>
          {data[col.value]}{' '}
        </RouterLink>
      );
    },
    productLink: (data, col) => {
      return (
        <RouterLink
          style={{textDecoration: 'none'}}
          to={`/product/${data['variantId']}`}
          onClick={() => {
            if (setSelected) {
              setSelected(0);
            }
          }}
        >
          {data[col.value]}{' '}
        </RouterLink>
      );
    },

    // '\u00A0' is used to add a space after comma(',') because following function actually returns
    // an array which is being converted into a string by the component and the extra space in
    // javascript is being ignored, also '&nbsp' is not working here due to the conditon. Hence, we needed something
    // from JS and not HTML
    variantIDs: (data, col) => {
      let variantIDs = data.variantIDs.split(',');
      let skus = data.skus.split(',');
      let length = variantIDs.length;
      return variantIDs.map((x, i) => (
        <div key={`variant-${i}`}>
          <RouterLink style={{textDecoration: 'none'}} to={`/product/${x}`}>
            {skus[i]}
          </RouterLink>
          {i < length - 1 ? `,\u00A0` : ''}
        </div>
      ));
    },
    rfmCategory: (data, col) => {
      return (
        <RouterLink
          style={{textDecoration: 'none'}}
          to={`/metrics/rfm-analysis/${data[col.value]}`}
        >
          {data[col.value]}
        </RouterLink>
      );
    },
    weeksOfInventory: (data, col) => {
      let value = formatNumber({value: data[col.value]});
      if (!verifyInventory(data)) value = 'NA';
      return <Inline align="end">{value}</Inline>;
    },
    orderBadge: (data, col) => {
      return (
        <Badge
          status={
            data[col.value] === 'paid'
              ? 'success'
              : data[col.value] === 'pending'
              ? 'attention'
              : ''
          }
        >
          {data[col.value]}
        </Badge>
      );
    },
    marketingDashboardDateLink: (data, col) => {
      if (data['date']) {
        return (
          <RouterLink
            style={{textDecoration: 'none'}}
            to={`/marketing-dashboard/${durationFilter}/${data[col.value]}`}
          >
            {setDate(data['date'], durationFilter)}
          </RouterLink>
        );
      }
      return '';
    },
    ...valueTypes,
  };

  useEffect(() => {
    if (isCohortReport) {
      setColHeading(
        tableData.length
          ? Object.keys(tableData[0])
              .filter(key => {
                return key !== 'cacBreakout';
              })
              .map((key, i) => {
                if (key === 'date') {
                  return {
                    title: (
                      <div
                        className="tableHeadLtv"
                        style={{textAlign: 'center'}}
                        key={`colHead-${i}`}
                      >
                        <Text as="span">
                          {durationFilter ? dateColumnSelector[durationFilter] : 'Date'}
                        </Text>
                      </div>
                    ),
                    value: key,
                  };
                }
                return {
                  title: (
                    <div
                      className="tableHeadLtv"
                      style={{textAlign: 'center'}}
                      key={`colHead-${i}`}
                    >
                      <Text as="span">
                        {key === 'repurchaseRate' ? 'Repurchase Rate' : key.toUpperCase()}
                      </Text>
                    </div>
                  ),
                  value: key,
                };
              })
          : [{title: ''}]
      );
    } else {
      setColHeading(
        columns.map((col, i) => {
          return {
            title: (
              <ColumnHeading
                _key={`colHeading-${i}`}
                col={col}
                sortBy={sortBy}
                sortOrder={sortOrder}
                durationFilter={durationFilter}
                setSortOrder={setSortOrder}
                setSortBy={setSortBy}
                disableSort={disableSort}
              />
            ),
            id: `colHeading-${i}`,
          };
        })
      );
    }

    let choices = [];

    columns.forEach(col => {
      if (col.sortable) {
        if (col.value === 'date') {
          choices.push({
            label: durationFilter ? dateColumnSelector[durationFilter] : 'Date',
            value: 'date',
          });
        } else {
          choices.push({label: col.title, value: col.value});
        }
      }
    });
    setSortChoices([...choices]);
  }, [tableData]);

  if (isCohortReport) {
    const {minimum, maximum} = getMinAndMax(tableData, durationFilter);
    var colorTones = tableData.map(data =>
      assignColorLightenValue(data, minimum, maximum, durationFilter)
    );
  }
  const rowMarkup = tableData
    .filter(key => {
      return key !== 'cacBreakout';
    })
    .map((data, index) => (
      <IndexTable.Row
        id={data.id || data._id}
        key={`row-${index}`}
        position={index}
        onClick={onRowClick && onRowClick}
      >
        {isCohortReport
          ? colHeading.map((col, i) => {
              const colorTone = colorTones[index];
              const color = ['date', 'cohort'].includes(col.value)
                ? ''
                : LightenDarkenColor('#003366', colorTone[col.value]);
              let borderColumnIndex = null;
              if (data['cacBreakout'] && cacOn) {
                let skipCount = colHeading.filter(col =>
                  ['cac', 'date', 'repurchaseRate'].includes(col.value)
                ).length;
                borderColumnIndex = Math.floor(data['cacBreakout']) + skipCount;
              }
              return (
                <IndexTable.Cell className="cell cohort-cell" key={`cell-${i}`}>
                  <div
                    style={{
                      backgroundColor: color,
                      borderRadius: '2px',
                      textAlign: 'center',
                      margin: '1px',
                      padding: 'var(--p-space-2) var(--p-space-4)',
                      position: 'relative',
                    }}
                  >
                    <Text variant="bodyMd" as="span">
                      {['date', 'cohort', 'repurchaseRate'].includes(col.value)
                        ? data[col.value]
                        : col.value === 'cac'
                        ? formatLtvAmount(data[col.value])
                        : formatFunction(data[col.value])}
                    </Text>
                    {borderColumnIndex !== null && i === borderColumnIndex && cacOn && (
                      <>
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: `${Math.round((data['cacBreakout'] % 1) * 100)}%`,
                            width: '5px',
                            height: '100%',
                            backgroundColor: 'green',
                            zIndex: 5,
                            boxSizing: 'border-box',
                            transform: 'translateX(-50%)',
                          }}
                        />
                      </>
                    )}
                  </div>
                </IndexTable.Cell>
              );
            })
          : columns.map((col, i) => {
              let value =
                col.value === 'items'
                  ? data[col.value]
                  : col.type
                  ? valueType[col.type](data, col)
                  : col.value === 'date' && data['date']
                  ? setDate(data['date'], durationFilter)
                  : data[col.value];
              let conditionalClasses = assignConditionalClasses(param, data, col);

              return (
                <IndexTable.Cell
                  className={`cell ${conditionalClasses.join(' ')}`}
                  key={`cell_${i}`}
                >
                  <TableColumnValue value={value} col={col} key={`value-${i}`} data={data} />
                </IndexTable.Cell>
              );
            })}
      </IndexTable.Row>
    ));

  const [sortPopoverActive, setSortPopoverActive] = useState(false);

  const toggleSortPopover = useCallback(
    () => setSortPopoverActive(sortPopoverActive => !sortPopoverActive),
    []
  );
  const [sort, setSort] = useState([sortBy]);

  const handleSortOptions = useCallback(value => {
    setSortBy(value[0]);
    setSort(value);
    setPage(1);
  }, []);

  const activator = (
    <Button onClick={toggleSortPopover}>
      <Stack spacing="extraTight" alignment="center">
        <Icon source={SortMinor} color="base" />
        <Text as="span">Sort</Text>
      </Stack>
    </Button>
  );

  return (
    <>
      <Card>
        {!isCohortReport && (
          <Card.Section>
            <div style={{display: 'flex'}}>
              <div style={{flex: 3}}>
                {isSearchEnabled && (
                  <TableFilters
                    setQueryValue={setQueryValue}
                    queryValue={queryValue}
                    selectedFilters={selectedFilters}
                    queryPlaceholder={queryPlaceholder}
                    setPage={setPage}
                  />
                )}
              </div>

              <Box paddingInlineStart={'3'}>
                <Inline>
                  {needSaveFilters && (
                    <Button>
                      <Stack spacing="extraTight" alignment="center">
                        <Icon source={StarOutlineMinor} color="base" />
                        <Text as="span">Save Filters</Text>
                      </Stack>
                    </Button>
                  )}
                  {Boolean(filters.length) && (
                    <>
                      {filters.map((filter, index) => {
                        return <div key={`filter-${index}`}>{filter}</div>;
                      })}
                    </>
                  )}
                  {actions && actions}

                  {Boolean(!disableSort) && (
                    <Stack>
                      <Popover
                        active={sortPopoverActive}
                        activator={activator}
                        autofocusTarget="first-node"
                        onClose={toggleSortPopover}
                        preferredAlignment={'right'}
                        fluidContent
                      >
                        <Box paddingBlockStart={'2'} paddingInlineStart={'4'}>
                          <Text color="subdued">Sort by</Text>
                        </Box>
                        <Popover.Section>
                          <ChoiceList
                            title="Selection"
                            titleHidden
                            choices={sortChoices}
                            selected={sort || []}
                            onChange={handleSortOptions}
                          />
                        </Popover.Section>
                        {resourceName.singular !== 'product' && (
                          <Popover.Section>
                            <Box paddingBlockEnd={'3'}>
                              <Box
                                onClick={() => {
                                  setSortOrder('asc');
                                  setPage(1);
                                }}
                                style={{cursor: 'pointer'}}
                              >
                                <TextContainer>
                                  <Inline>
                                    <Box>
                                      <Icon
                                        source={ArrowUpMinor}
                                        color={sortOrder === 'asc' ? 'interactive' : 'subdued'}
                                      />
                                    </Box>
                                    <div style={{color: '#2E72D2'}}>
                                      <Text color={sortOrder !== 'asc' && 'subdued'}>
                                        Ascending
                                      </Text>
                                    </div>
                                  </Inline>
                                </TextContainer>
                              </Box>
                            </Box>

                            <Box paddingBlockEnd={'3'}>
                              <Box
                                onClick={() => {
                                  setSortOrder('desc');
                                  setPage(1);
                                }}
                                style={{cursor: 'pointer'}}
                              >
                                <TextContainer>
                                  <Inline>
                                    <Box>
                                      <Icon
                                        source={ArrowDownMinor}
                                        color={sortOrder === 'desc' ? 'interactive' : 'subdued'}
                                      />
                                    </Box>
                                    <div style={{color: '#2E72D2'}}>
                                      <Text color={sortOrder !== 'desc' && 'subdued'}>
                                        Descending
                                      </Text>
                                    </div>
                                  </Inline>
                                </TextContainer>
                              </Box>
                            </Box>
                          </Popover.Section>
                        )}
                      </Popover>
                    </Stack>
                  )}
                </Inline>
              </Box>
            </div>
          </Card.Section>
        )}
        {isCohortReport && !isCompareCohort && (
          <Card.Section>
            <BasicFilter handleFiltersUpdate={onChange} />
          </Card.Section>
        )}
        {isCompareCohort && (
          <Card.Section>
            <AddCohort
              cohorts={cohorts}
              setCohorts={setCohorts}
              state={state}
              setTableData={setTableData}
              tableData={tableData}
              data={data}
              setData={setData}
            />
          </Card.Section>
        )}
        <IndexTable
          resourceName={resourceName}
          itemCount={tableData.length}
          hasMoreItems
          headings={colHeading}
          selectable={select}
          loading={!state || state === 'Loading'}
          emptyState={
            <EmptySearchResult
              title={
                state && state !== 'Loading' && state === 'Error'
                  ? 'Not able to load data'
                  : state === 'Loading'
                  ? ''
                  : isCohortReport
                  ? 'Comparing cohorts helps you discover meaningful differences in LTV'
                  : isFixedCostReport
                  ? 'No custom fixed costs found'
                  : 'No metrics found'
              }
              description={
                state === 'Error'
                  ? 'Please try again later'
                  : !state || state === 'Loading'
                  ? ''
                  : isCohortReport
                  ? 'Use add cohort button to create different cohorts'
                  : isFixedCostReport
                  ? 'Add custom fixed costs for profitability computation'
                  : 'Try changing the filters or search term'
              }
              withIllustration={state && state !== 'Loading' && state !== 'Error'}
            />
          }
        >
          {rowMarkup}
        </IndexTable>
        {!isCohortReport && (
          <Box padding={'2'} paddingBlockEnd={'4'}>
            <Inline align="center">
              {totalRows ? (
                <Pagination
                  hasNext={page * 50 < totalRows}
                  onNext={() => {
                    setPage(page + 1);
                  }}
                  hasPrevious={page > 1}
                  onPrevious={() => {
                    setPage(page - 1);
                  }}
                  label={`Showing ${page} of ${Math.ceil(totalRows / 50)} pages`}
                />
              ) : (
                ''
              )}
            </Inline>
          </Box>
        )}
      </Card>
    </>
  );
};

export default DefaultIndexTable;
