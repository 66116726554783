const tooltipText = {
  'gross-revenue':
    'Sum total of all product sales at full price (pre-discount), including shipping, taxes, discount etc. Sales from all channels including cancelled orders.',
  products: '',
  'cost-of-goods-sold': 'Sum total of item’s cost sold in a specific duration',
  'discount-code-usage':
    'Lists coupon codes in descending order of number of times it has been used.',
  'repeat-customers':
    'Revenue generated in the given time frame by customers who have purchased before. Shows both absolute and percentage net numbers.',
  'abandoned-checkouts':
    'List of products which have the most number of cart abandoned cases i.e. added to cart but not purchased by customers.',
  'province-wise-orders':
    'Lists number of orders received from different provinces in descending order of net revenue. Includes orders from all channels.',
  'rfm-analysis':
    'Number of customers in each segment created (default and custom) based on recency, frequency and monetary value of purchases made. ',
  'net-revenue':
    'Gross sales less discounts and returns. Includes sales from all channels. All returns, cancellations and refunds are adjusted retrospectively to the day that the order was placed, as opposed to the day on which the order was cancelled, or refunded. So over time, it is possible that you may see the Net revenue of a previous day change, as any of that day’s orders are returned or cancelled. We feel this is a neater way of looking at revenue vs a scenario where all cancellations/refunds may be processed in bulk.',
  'country-wise-orders':
    'Lists number of orders received from different countries in descending order of net revenue. Includes orders from all channels.',
  'avg-order-value': 'Net revenue in the duration divided by the number of orders',
  discounts:
    'Sum of discounts offered on all orders in a give time frame, as well as the average discount percentage. Includes orders from all channels.',
  'revenue-cohort': '',
  'first-order-value-of-repeat-customers':
    'Average first order value of customers who have made a repeat purchase in this duration.',
  customers: 'Unique people who have gone on to make a purchase in the specified duration.',
  'best-sellers':
    'List of products which contribute most to sales in descending order of net revenue generated.',
  returns:
    'Return percentage based on number of orders returned on gross less cancelled orders. All returns, cancellations and refunds are adjusted retrospectively to the day that the order was placed, as opposed to the day on which the order was cancelled, or refunded. So over time, it is possible that you may see the Returns% of a previous day change, as any of that day’s orders are returned or cancelled. We feel this is a neater way of looking at revenue vs a scenario where all cancellations/refunds may be processed in bulk.',
  'items-per-order':
    'Average number of units ordered per order over a given time frame. Includes orders from all channels.',
  'lifetime-value':
    'Average Lifetime revenue of customers who have purchased in a given timeframe, including past purchases. Calculated based on net revenues from all channels.',
  'revenue-by-source':
    'Shows Net revenues split by each channel including website, draft orders and any other channels configured within Shopify.',
  'city-wise-orders':
    'Lists number of orders received from different cities in descending order of net revenue. Includes orders from all channels.',
  'pincode-wise-orders':
    'Lists number of orders received from different pincodes in descending order of net revenue. Includes orders from all channels.',
  'customer-cohort': '',
  'marketing-dashboard':
    'Aggregation of net revenue from sales on shopify with ad spend on all marketing channels',
};

export {tooltipText};
