import {Tabs, Box} from '@shopify/polaris';
import React, {useCallback, useState} from 'react';
import Page from '../../../components/page';
import Connections from './connections';
import ReportSettings from './reportSetting';
// import Team from './team';
import {WithPrimitiveComponents} from './npsSetting';
import {useSearchParams} from 'react-router-dom';
import {LocalStorageService} from '../../../service/localStorage';

const SettingsPage = () => {
  const userDetails = LocalStorageService.getUserOrg();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('_') ? Number(searchParams.get('_')) - 1 : 0;
  const [selected, setSelected] = useState(tab || 0);
  const handleTabChange = useCallback(selectedTabIndex => {
    setSelected(selectedTabIndex);
    setSearchParams({_: selectedTabIndex + 1});
  }, []);

  const tabs = [
    {
      id: 'connections',
      content: 'Connections',
      accessibilityLabel: 'connections',
      panelID: 'connections-content-1',
      component: <Connections />,
    },
    {
      id: 'exclude',
      content: 'Exclude Data',
      panelID: 'exclude-data-1',
      component: <ReportSettings />,
    },
    // {
    //   id: 'nps',
    //   content: 'NPS',
    //   panelID: 'nps-content-1',
    //   component: <WithPrimitiveComponents />,
    // },
    // {
    //   id: 'team',
    //   content: 'Team',
    //   panelID: 'team-content-1',
    //   component: <Team />,
    // },
  ];
  if (userDetails.name !== 'giva') {
    tabs.push({
      id: 'nps',
      content: 'NPS',
      panelID: 'nps-content-1',
      component: <WithPrimitiveComponents />,
    });
  }
  return (
    <>
      <Page title="Settings" subtitle="View and manage your settings">
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Box paddingBlockStart={'6'} paddingInlineStart={'3'}>
            {tabs[selected].component}
          </Box>
        </Tabs>
      </Page>
    </>
  );
};

export default SettingsPage;
