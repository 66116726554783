import {Spinner} from '@shopify/polaris';
import Card from '../../../lib/Card';
import Box from '../../../lib/Box';
import Inline from '../../../lib/Inline';
import {
  thirtyDaysBefore,
  todayDate,
  yesterDayDate,
  ninetyDaysBefore,
  sevenDaysBefore,
  dateToNormalString,
  previousPeriod,
  previousYear,
  toClickHouseDate,
  convertToOrgTimezone,
  oneYearBefore,
} from '../../../features/convertDates';
import React from 'react';
import {useEffect} from 'react';
import {useCallback} from 'react';
import {useState} from 'react';
import DateCompareComponent from '../../../components/dateCompareComponent';
import DateRangeComponent from '../../../components/dateRangeComponent';
import Page from '../../../components/page';
import SelectComponent from '../../../components/selectComponent';
import DefaultLineGraph from '../../../components/graphs/defaultLineGraph';
import DefaultTable from '../../../components/defaultIndexTable';
import apiToCall from '../../../features/apiToCall';
import Indicator from '../reportPage/indicator';
import {getRevenueValues} from '../reportPage/revenueValues';
import {RequestApi} from '../../../api/requestReports';
import config from '../../../config';
import {ErrorBoundary} from '../../../components/ErrorBoundary';
import {LineChart} from '@shopify/polaris-viz';
import {track} from '../../../features/track';
import Alert from '../../../components/alert';
import useApiCall from '../../../hooks/useApiCall';
import DownloadModal from '../../../components/Modals/downloadModal';
import {useDateRangeStore} from '../../../app/store';
import useSearchParamStoreSync from '../../../hooks/useSearchParamsStoreSync';
import Button from '../../../lib/Button';

const dateRangeObj = {
  yesterday: yesterDayDate,
  today: todayDate,
  lastThirtyDays: thirtyDaysBefore,
  lastSevenDays: sevenDaysBefore,
  lastNinetyDays: ninetyDaysBefore,
  lastOneYear: oneYearBefore,
};
const dateCompareObj = {
  previousPeriod: previousPeriod,
  previousYear: previousYear,
  noComparison: () => {
    return ['', ''];
  },
};
const dateRangeValueToLable = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  lastNinetyDays: 'Last 90 Days',
  lastOneYear: 'Last 1 Year',
};
const dateCompareValueToLable = {
  previousPeriod: 'Previous Period',
  previousYear: 'Previous Year',
  noComparison: 'No Comparison',
};
const Customers = () => {
  const param = {name: 'customers'};
  const {
    selectedDateRange,
    setSelectedDateRange,
    selectedCompare,
    setSelectedCompare,
    durationFilter,
    setDurationFilter,
    selectedDateRangeDates,
    setSelectedDateRangeDates,
    setSelectedCompareDates,
    selectedCompareDates,
  } = useDateRangeStore(state => state);

  useSearchParamStoreSync(['durationFilter', 'selectedDateRangeDates']);

  const [sortOrder, setSortOrder] = useState(apiToCall['customers'].sortOrder);
  const [queryValue, setQueryValue] = useState('');
  const [revenue, setRevenue] = useState([]);

  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(apiToCall['customers'].sortBy);

  const [dateRangePopoverActive, setDateRangePopoverActive] = useState(false);

  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive(popoverActive => !popoverActive),
    []
  );
  const [download, setDownload] = useState({state: 'Success'});
  const [showAlert, setShowAlert] = useState('');

  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  useEffect(() => {
    track('Page View', {
      name: 'Customers',
    });
    if (selectedDateRange) {
      setSelectedDateRangeDates({
        start: dateRangeObj[selectedDateRange]()[0],
        end: dateRangeObj[selectedDateRange]()[1],
      });
    }
    RequestApi.updateLastViewed({slag: 'customers'});
  }, []);

  const activator = (
    <div style={{width: '100%'}}>
      <Button onClick={togglePopoverActive} disclosure>
        Compare:{' '}
        {selectedCompare
          ? dateCompareValueToLable[selectedCompare]
          : dateToNormalString(selectedCompareDates)}
      </Button>
    </div>
  );

  const getDates = (dates, selectedCompar) => {
    setSelectedCompareDates(dates);
    setSelectedCompare(selectedCompar[0]);
  };
  const getDateRangeDates = (dates, selectedDateRange) => {
    setSelectedDateRangeDates(dates);
    setSelectedDateRange(selectedDateRange);
  };
  const dateRangeTogglePopoverActive = useCallback(
    () => setDateRangePopoverActive(dateRangePopoverActive => !dateRangePopoverActive),
    []
  );

  const dateRangeActivator = (
    <div style={{width: '100%'}}>
      <Button onClick={dateRangeTogglePopoverActive} disclosure>
        {selectedDateRange
          ? dateRangeValueToLable[selectedDateRange]
          : dateToNormalString(selectedDateRangeDates)}
      </Button>
    </div>
  );

  useEffect(() => {
    // compare Dates auto change on selected date changed
    if (selectedCompare) {
      let [sdate, edate] = dateCompareObj[selectedCompare](selectedDateRangeDates);
      setSelectedCompareDates({start: sdate, end: edate});
    }
  }, [selectedDateRangeDates]);

  const fetchInfoData = async () => {
    let response = await apiToCall['customers'].api({params: {type: 'info'}});
    if (response.data.status) {
      let rvalues = getRevenueValues(response.data.data[0]);
      setRevenue([...rvalues]);
    }
    return response.data;
  };
  const infoApiDependencies = [];

  const fetchGraphData = async () => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let params = {};
    if (selectedCompareDates.start !== 'Invalid Date') {
      let compareStartDate = convertToOrgTimezone(
        toClickHouseDate(selectedCompareDates.start, 'start')
      );
      let compareEndDate = convertToOrgTimezone(toClickHouseDate(selectedCompareDates.end, 'end'));
      params = {...params, compareStartDate, compareEndDate};
    }

    params = {
      ...params,
      startDate,
      endDate,
      type: 'graph',
      durationFilter,
    };
    let response = await apiToCall['customers'].api({params});
    return response.data;
  };

  let graphApiDependencies = [selectedDateRangeDates, durationFilter, selectedCompareDates];

  const fetchTableData = async () => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    let params = {
      startDate,
      endDate,
      page,
      search: queryValue,
      sortBy,
      sortOrder,
      type: 'table',
    };
    let response = await apiToCall['customers'].api({params});
    return response.data;
  };

  const tableApiDependencies = [selectedDateRangeDates, page, queryValue, sortBy, sortOrder];

  const infoData = useApiCall(fetchInfoData, infoApiDependencies);
  const graphData = useApiCall(fetchGraphData, graphApiDependencies);
  const tableData = useApiCall(fetchTableData, tableApiDependencies, config.SEARCH_DELAY);

  const handleDownload = async email => {
    let startDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.start, 'start'));
    let endDate = convertToOrgTimezone(toClickHouseDate(selectedDateRangeDates.end, 'end'));
    const params = {
      type: 'download',
      startDate,
      endDate,
      search: queryValue,
      sortBy,
      sortOrder,
      page,
      email,
    };
    setDownload({state: 'Loading'});
    try {
      await apiToCall['customers'].api({params});
      setShowAlert(`Customers data will be mailed to you shortly on ${email}`);
      setDownload({state: 'Success'});
    } catch (e) {
      setDownload({state: 'Error'});
    }
  };
  return (
    <>
      <Page
        secondaryActions={[
          // {
          //   content: 'Create signal',
          //   destructive: false,
          //   onAction: () => {
          //     navigate(`/signal-new/${'customers'}`);
          //   },
          // },
          {
            content: download.state === 'Loading' ? <Spinner size="small" /> : 'Download',
            destructive: false,
            onAction: () => {
              setOpenDownloadModal(true);
            },
          },
        ]}
        title={apiToCall['customers'].title}
      >
        <Box paddingBlockEnd={'4'}>
          {apiToCall['customers'].graph === 'line' && (
            <ErrorBoundary
              fallback={
                <Card>
                  <Card.Section>
                    <div>An error has occured</div>
                  </Card.Section>
                </Card>
              }
            >
              <Indicator
                symbol={apiToCall['customers'].symbol}
                data={infoData.data}
                state={infoData.state}
                revenue={revenue}
              />
            </ErrorBoundary>
          )}
        </Box>
        <Box paddingBlockEnd={'4'}>
          <Inline>
            <DateRangeComponent
              currentDates={selectedDateRangeDates}
              activator={dateRangeActivator}
              togglePopoverActive={dateRangeTogglePopoverActive}
              popoverActive={dateRangePopoverActive}
              getDates={getDateRangeDates}
              currentDateOption={selectedDateRange}
            />

            <DateCompareComponent
              currentDates={selectedCompareDates}
              activator={activator}
              togglePopoverActive={togglePopoverActive}
              popoverActive={popoverActive}
              selectedCompare={selectedCompare}
              getDates={getDates}
              selectedDateRangeDates={selectedDateRangeDates}
            />
            <SelectComponent
              setDurationFilter={setDurationFilter}
              durationFilter={durationFilter}
              setPage={setPage}
              setQueryValue={setQueryValue}
            />
          </Inline>
        </Box>
        <Box paddingBlockEnd={'4'}>
          <Card>
            <Card.Section>
              <ErrorBoundary fallback={<LineChart data={[]} state="Error" theme="Light" />}>
                <DefaultLineGraph
                  data={graphData.data}
                  compareStartDate={toClickHouseDate(selectedCompareDates.start, 'start')}
                  compareEndDate={toClickHouseDate(selectedCompareDates.end, 'end')}
                  startDate={toClickHouseDate(selectedDateRangeDates.start, 'start')}
                  endDate={toClickHouseDate(selectedDateRangeDates.end, 'end')}
                  param={{name: 'customers'}}
                  durationFilter={durationFilter}
                  state={graphData.state}
                />
              </ErrorBoundary>
            </Card.Section>
          </Card>
        </Box>

        <Box paddingBlockEnd={'4'}>
          <ErrorBoundary
            fallback={
              <Card>
                <Card.Section>
                  <div>An error has occured</div>
                </Card.Section>
              </Card>
            }
          >
            <DefaultTable
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortExceptions={[...apiToCall['customers'].sortExecptions]}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              select={false}
              queryValue={queryValue}
              setQueryValue={setQueryValue}
              page={page}
              setPage={setPage}
              resourceName={{
                singular: 'metrics',
                plural: 'metrics',
              }}
              columns={apiToCall['customers'].columns}
              selectedFilters={[]}
              totalRows={tableData.data.length > 1 ? +tableData.data[0].totalCount : 0}
              tableData={tableData.data.length > 1 ? tableData.data[1] : []}
              durationFilter={durationFilter}
              state={tableData.state}
              param={{name: 'customers'}}
              queryPlaceholder="Filter name , email or mobile number of customer"
            />
          </ErrorBoundary>
        </Box>
        {showAlert && (
          <Alert
            contents={showAlert}
            init={true}
            error={showAlert === 'Something Went Wrong'}
            duration={10000}
          />
        )}
      </Page>
      <DownloadModal
        isOpen={openDownloadModal}
        toggle={() => setOpenDownloadModal(!openDownloadModal)}
        handleDownload={handleDownload}
      />
    </>
  );
};

export default Customers;
