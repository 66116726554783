/**
 * converts number values to percentage , takes firstOrder value as base and converts each key at array indexes to percent value
 * @param {*} data data array
 * @returns array with percent values
 */

function setPercentageValues(data) {
  const responseData = Array.from(data);
  const updatedResponseData = responseData
    .filter(res => res['date'] !== 'Average')
    .map(res => {
      let totalValue = res['firstOrder'];
      let keys = Object.keys(res);
      const updatedRes = {};
      if (res.date) {
        updatedRes.date = res.date;
      }
      if (res.cac != null) {
        updatedRes.cac = res.cac;
      }
      if (res.repurchaseRate != null) {
        updatedRes.repurchaseRate = res.repurchaseRate;
      }
      if (res.cohort) {
        updatedRes.cohort = res.cohort;
      }

      keys.forEach(key => {
        if (!['date', 'cohort', 'repurchaseRate', 'cac'].includes(key)) {
          updatedRes[key] = res[key] > 0 ? ((res[key] * 100) / totalValue).toFixed(1) : 0;
        }
      });

      return updatedRes;
    });

  for (let data in responseData) {
    if (responseData[data].date === 'Average') {
      const averagePercentage = {};
      averagePercentage['date'] = 'Average';
      averagePercentage['firstOrder'] = (
        (responseData[data].firstOrder * 100) /
        responseData[data].firstOrder
      ).toFixed(1);
      let keys = Object.keys(responseData[data]);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== 'date' && keys[i] !== 'cac' && keys[i] !== 'repurchaseRate') {
          let key = keys[i];
          let length;
          const numerator = responseData
            .filter(res => res['date'] !== 'Average')
            .reduce((sum, item, index) => {
              if (item[key] >= 0) {
                length = index;
                return sum + item[key];
              } else {
                return sum;
              }
            }, 0);
          let denominator = responseData
            .slice(0, length + 1) //length +1 to include that index
            .reduce((sum, item) => sum + item.firstOrder, 0);
          denominator = denominator === 0 ? 1 : denominator;
          averagePercentage[key] = ((numerator * 100) / denominator).toFixed(1);
        }
      }
      averagePercentage['cac'] = responseData[data].cac;
      averagePercentage['repurchaseRate'] = responseData[data].repurchaseRate;
      updatedResponseData.push(averagePercentage);
    }
  }
  return updatedResponseData;
}

export {setPercentageValues};
